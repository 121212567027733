import React from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Button } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function UserAgreementPage() {
  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full flex-col items-center gap-5 bg-white-A700 md:gap-24 sm:gap-16">
        <Header className="self-stretch" />
        <div className="pt-20 md:pt-28 container-sm pl-[75px] pr-[75px] md:p-5 md:px-5">
          <div className="flex flex-col items-center gap-[25px] sm:gap-[33px]">
            <div className="flex w-[67%] flex-col items-center gap-[7px] md:w-full"></div>

            <Heading as="h1"> User Agreement </Heading>

            <div className="flex flex-col items-start self-stretch">
              <Text as="p" className="mt-3 w-full !font-normal leading-7"> &nbsp;This Agreement
                ("Agreement") is made effective starting from the date of issue of the barter certificate
                ("Effective Date"), by and between Quickall Finance Inc., a corporation organized and
                existing under the laws of California, USA ("Quickall"), and [Name of Money Manager],
                an individual or entity ("Money Manager").&nbsp;
                <p> &nbsp;WHEREAS, Quickall
                  Finance Inc. agrees to issue barter certificates to the Money Manager against the
                  acceptance of US dollars.&nbsp;</p>

                <p>NOW, THEREFORE, in consideration of the mutual promises and agreements
                  contained herein, the parties agree as follows:</p></Text>

            </div>


            <Text as="p" className="mt-3 w-full !font-normal leading-7">
              <strong>1. Issuance of Barter Certificate&nbsp;</strong></Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7">
              &nbsp;1.1	Effective Date: The issuance of the barter certificate shall commence on the date of issue.</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7">
              1.2	Duration: This Agreement shall remain in effect for a period
              of six (6) months from the Effective Date.</Text>

            <Text as="p" className="mt-3 w-full !font-normal leading-7">1.3	Investment Amount:
              The investment amount by the Money Manager is flexible and shall be specified during
              the transaction on the Quickall website.&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7">
              <strong>2. Barter Certificate Details</strong> &nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 2.1	Value: The value of each barter certificate will be equivalent to the amount invested in US dollars.&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 2.2	Expiration Date: The barter certificate will expire six (6) months from the date of issue.&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 2.3	Conditions of Exchange: Upon the completion of the blockchain development, the barter certificate can be exchanged for an equivalent value of G coins.&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>3. Payment Terms&nbsp;</strong>&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> Payment Method: The Money Manager shall make the payment through the Quickall website (www.quickall.com) using the available payment methods.&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>4. Rights and Obligations</strong></Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 4.1	Money Manager's Rights: The Money Manager shall have the right to exchange the barter certificate for G coins under the terms specified in this Agreement.&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 4.2	Money Manager's Obligations: The Money Manager agrees to make the payment in US dollars through the Quickall website and adhere to the terms and conditions of this Agreement.&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 4.3	Quickall's Rights: Quickall reserves the right to modify the terms of the barter certificate issuance, provided such modifications do not adversely affect the Money Manager's existing certificates.&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 4.4	Quickall's Obligations: Quickall agrees to issue the barter certificates and ensure their exchangeability for GGQ coins upon blockchain readiness.&nbsp; &nbsp; </Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>O5. Dispute Resolution&nbsp;</strong></Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> Arbitration: Any dispute arising from or relating to this Agreement shall be resolved by arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in California, USA.</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>6. Governing Law</strong></Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> Jurisdiction: This Agreement shall be governed by and construed in accordance with the laws of the State of California, USA.&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> <strong>7. Limitation of Liability</strong></Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> Under no circumstances shall
              Quickall Finance Inc., its contracted providers, officers, agents, or anyone else
              involved in creating, producing, or distributing the Barter Certificate be liable for
              any direct, indirect, incidental, special, or consequential damages that result from the
              use of or inability to use the Barter Certificate; or that result from mistakes,
              omissions, interruptions, deletion of files, errors, defects, delays in operation or
              transmission, or any failure of performance, whether or not limited to acts of God,
              communication failure, theft, destruction, or unauthorized access to the Money Manager's
              records, programs, or services. THE TOTAL LIABILITY FOR ALL DAMAGES, LOSSES, AND CAUSES
              OF ACTION (WHETHER IN CONTRACT, OR INCLUDING NEGLIGENCE, OR OTHERWISE) TO THE OTHER SHALL
              NOT EXCEED THE AMOUNT INVESTED BY THE MONEY MANAGER IN THE BARTER CERTIFICATE.&nbsp;</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>8. Miscellaneous&nbsp;</strong></Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 8.1	Entire Agreement: This Agreement constitutes the entire understanding between the parties with respect to the subject matter hereof and supersedes all prior agreements, understandings, and representations.</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 8.2	Amendments: Any amendments or modifications to this Agreement must be in writing and signed by both parties.</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 8.3	Severability: If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall continue in full force and effect.</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"><strong>9. Termination by Quickall&nbsp;</strong></Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 9.1	Quickall reserves the
              right to terminate this Agreement at any time by providing written notice to the Money
              Manager. In the event of such termination, Quickall Finance Inc. shall refund the full
              amount invested by the Money Manager in the Barter Certificate, along with an interest
              rate of 6% per annum, calculated from the date of the investment until the date of
              termination. The refund and interest payment shall be made within thirty (30) days of
              the termination notice.</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> 9.2	If Quickall fails to
              complete the development of the blockchain within six (6) months from the Effective Date,
              the Money Manager shall have the right to terminate this Agreement. Upon such termination,
              Quickall Finance Inc. shall refund the full amount invested by the Money Manager in the
              Barter Certificate, along with an interest rate of 6% per annum, calculated from the date
              of the investment until the date of termination. The refund and interest payment shall be
              made within thirty (30) days of the termination notice provided by the Money Manager.
              The termination notice must be given in writing to Quickall.</Text>
            <Text as="p" className="mt-3 w-full !font-normal leading-7"> IN WITNESS WHEREOF, the parties have executed this Agreement as of the Effective Date.</Text>
          </div>
        </div>
        <Footer className="self-stretch" />
      </div>




    </>
  );
}
