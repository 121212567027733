import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Heading, Text } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useForm } from "react-hook-form";
import axios from "axios";


const Input = React.forwardRef(({ ...props }, ref) => (
  <input ref={ref} {...props} className="border border-solid rounded-lg h-[64px] px-[33px] text-base" />
));

const TextArea = React.forwardRef(({ ...props }, ref) => (
  <textarea ref={ref} {...props} className="min-h-[120px] h-[138px] p-[33px] text-base border border-solid rounded-lg" />
));

export default function ContactusPage() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true); // Show loader
    try {
      const response = await axios.post('https://quickall.io:3001/send-email', data);
      setSuccessMessage('Your message has been sent successfully!');
      setErrorMessage(''); // Clear previous errors if any
      reset(); // Reset the form fields
    } catch (error) {
      console.error('Error sending email:', error);
      setErrorMessage('There was an error sending your message. Please try again.');
      setSuccessMessage(''); // Clear previous success messages if any
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <div className="bg-[#fff] flex">
      <Helmet>
        <title>QUICKALL</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex w-full flex-col items-center gap-12 bg-white md:gap-10 sm:gap-8">
        <Header />

        <div className="pt-[120px] md:pt-28 w-full max-w-4xl px-4 sm:px-6 bg-[#fff]">
          <div className="flex flex-col items-center gap-8">
            <div className="w-full flex flex-col items-center gap-4 text-center">
              <Heading as="h1">Contact Us</Heading>
              <Text as="p">
                Get in touch with Quickall for inquiries and support. We're here to assist you.
              </Text>
            </div>
            <div className="w-full max-w-4xl px-4 sm:px-6 bg-[#fff]">
              <div className="flex flex-col items-center gap-8">
                <div className="w-full rounded-lg bg-gray-100 p-6">
                  <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-1 gap-4">
                      <div className="flex flex-col gap-1">
                        <p className="text-blue_gray-900_b2 font-poppins text-base font-medium">Name</p>
                        <label className="flex items-center justify-center cursor-text text-base border border-solid rounded-lg h-[64px] px-[33px] text-base">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            {...register("name", { required: "Name is required" })}
                          />
                        </label>
                        {errors.name && <p className="text-red-500">{errors.name.message}</p>}
                      </div>
                    </div>

                    <div className="grid grid-cols-1 gap-4">
                      <div className="flex flex-col gap-1">
                        <p className="text-blue_gray-900_b2 font-poppins text-base font-medium">Email</p>
                        <label className="flex items-center justify-center cursor-text text-base border border-solid rounded-lg h-[64px] px-[33px] text-base">
                          <Input
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            {...register("email", { required: "Email is required", pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email address" } })}
                          />
                        </label>
                        {errors.email && <p className="text-red-500">{errors.email.message}</p>}
                      </div>
                    </div>

                    <div className="grid grid-cols-1 gap-4">
                      <div className="flex flex-col gap-1">
                        <p className="text-blue_gray-900_b2 font-poppins text-base font-medium">Subject</p>
                        <label className="flex items-center justify-center cursor-text text-base border border-solid rounded-lg h-[64px] px-[33px] text-base">
                          <Input
                            type="text"
                            name="subject"
                            placeholder="Provide context"
                            {...register("subject", { required: "Subject is required" })}
                          />
                        </label>
                        {errors.subject && <p className="text-red-500">{errors.subject.message}</p>}
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <p className="text-blue_gray-900_b2 font-poppins text-base font-medium">Message</p>
                      <TextArea
                        name="message"
                        placeholder="Write your question here"
                        {...register("message", { required: "Message is required" })}
                      />
                      {errors.message && <p className="text-red-500">{errors.message.message}</p>}
                    </div>

                    <button
                      type="submit"
                      className="mt-4 self-start sm:self-stretch min-w-[220px] border-2 border-solid border-white-A700 font-medium md:ml-0 sm:px-5 flex flex-row items-center justify-center text-center cursor-pointer rounded-[0px] h-[60px] px-[35px] text-base bg-teal-900 text-white-A700"
                    >
                      {loading ? (
                        <div className="loader"></div>
                      ) : (
                        "Send Message"
                      )}
                    </button>

                    {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
                    {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
                  </form>
                </div>
              </div>

              <style jsx>{`
        .loader {
          border: 4px solid rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          border-top: 4px solid #fff;
          width: 24px;
          height: 24px;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
