import React from "react";
import { Link } from "react-router-dom";
import { Text, Img } from "./..";
import copyrightIcon from "../../assets/images/copyright.png";
import fackbookIcon from "../../assets/images/social-icons/facebook.png";
import twitterIcon from "../../assets/images/social-icons/twitter.png";
import instagramIcon from "../../assets/images/social-icons/instagram.png";
import pinterestIcon from "../../assets/images/social-icons/pinterest.png";
import linkedinIcon from "../../assets/images/social-icons/linkedin.png";

export default function Footer({ ...props }) {

  return (
    <footer {...props} className="w-full">
      <div className="w-full">
        <div className="flex justify-center bg-[#5f2c0f] pt-12 md:pt-5">
          <div className="container-xs flex md:pb-0  md:p-5 sm:w-[90%] md:w-full">
            <div className="flex w-full flex-col sm:px-[0.5px] lg:px-2">
              <div className="flex h-fit w-full items-center justify-between sm:items-end flex-row sm:p-0 pb-3">
                  <div className="flex md:w-[40%] lg:w-[70%] items-center ">
                    <Img
                      src="images/logo_transperent.svg"
                      alt="logo image"
                      className="h-[60px] w-[60px] object-cover sm:h-[40px] sm:w-[40px]"
                    />
                    <Text
                      as="h1"
                      className="!text-white-A700 md:text-xl text-5xl font-bold pl-2 sm:p-0"
                    >
                      QUICKALL
                    </Text>
                  </div>
                  <div className="h-full flex w-fit ">
                    <Text
                      as="h1"
                      className="!text-white-A700 md:text-3xl sm:text-2xl text-5xl text-left"
                    >
                      Let's Talk!
                    </Text>
                  </div>
              </div>

              {/* Footer Description Section */}
              <div className=" ml-[11px] mt-[-5px] flex flex-col gap-3.5 md:ml-0 sm:mt-[10px]">
                <div className="flex h-fit w-full items-start justify-between gap-5 md:flex-row">
                  <Text
                    as="p"
                    className="w-[48%] leading-7 sm:text-xs !text-white-A700_cc md:w-1/2"
                  >
                    Driving Innovation by decentralized AI in a blockchain technology. Secure, decentralized automated solutions 
                    designed to transform industries and fuel future growth. Join Quickall today.
                  </Text>
                  <div className="flex-1 flex flex-col justify-center items-end">
                    <Text
                      as="p"
                      className="sm:w-full w-3/4 !font-normal sm:text-xs leading-7 !text-gray-100_cc text-left"
                    >
                      How Quickall blockchain driven by decentralized
                      AI can bring innovation and automated solutions to your business. .{" "}
                    </Text>
                  </div>
                </div>
                <div className="flex w-full h-fit items-start md:w-full flex-row">
                  <div className="sm:w-1/2 flex flex-1 bg-white-A700 sm:p-0 px-4 pb-[15px] pt-[18px] sm:px-1">
                    <div className="flex w-full flex-row sm:hidden gap-[5px]">
                      <div className="flex h-fit w-1/2 sm:w-full flex-wrap justify-between gap-[0.5px] flex-col">
                        <Text
                          size="md"
                          as="p"
                          className="!text-blue_gray-900_01 break-words"
                        >
                          Email us at
                        </Text>
                        <a
                          href="mailto:contact@quickall.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Text
                            as="p"
                            className="!font-normal !text-blue_gray-900_cc break-all"
                          >
                            contact@quickall.com
                          </Text>
                        </a>
                      </div>
                      <div className="flex h-fit w-1/2 sm:w-full flex-wrap justify-between gap-[0.5px] flex-col">
                        <Text
                          size="md"
                          as="p"
                          className="!text-blue_gray-900_01 break-words"
                        >
                          Call us
                        </Text>

                        <Text
                          as="p"
                          className="self-start !font-normal !text-blue_gray-900_cc break-words"
                        >
                          +1 (818) 799-8174
                        </Text>
                      </div>
                    </div>
                  </div>
                  
                  
            <div className="md:hidden w-1/2 sm:w-[36%] flex justify-center items-center gap-4 flex-wrap">
                   
                  <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0348ZECJe1meyGUahQPHmYCNfqz62QUnPXrtQQVkzQARVc5crTb1VAvY1wRXb4gSKYl&id=61561059673151"
                    target="_blank" 
                    rel="noopener noreferrer"
                  >              
                  <img
                    className="sm:h-7 sm:w-7"
                    src={fackbookIcon}
                    alt="..."
                    height={"30px"}
                    width={"30px"}
                  />
                  </a>
                  <a href="https://www.linkedin.com/company/quickall-finance-inc/"
                    target="_blank" 
                    rel="noopener noreferrer"
                  >              
                    <img
                      className="sm:h-7 sm:w-7"
                      src={linkedinIcon}
                      alt="..."
                      height={"30px"}
                      width={"30px"}
                    />
                  </a>

              <a href="https://x.com/quickallG"
                  target="_blank" 
                  rel="noopener noreferrer"
                  >
                    <img
                      className="sm:h-10 sm:w-10"
                      src={twitterIcon}
                      alt="..."
                      height={"30px"}
                      width={"30px"}
                    />

                  </a>

                  <a href="https://www.instagram.com/quickall1/"
                  target="_blank" 
                  rel="noopener noreferrer"
                  >
                    <img
                      className="sm:h-10 sm:w-10"
                      src={instagramIcon}
                      alt="..."
                      height={"30px"}
                      width={"30px"}
                    />
                    </a>

                    <a href="https://www.pinterest.com/Quickall24/"
                  target="_blank" 
                  rel="noopener noreferrer"
                  >
                    <img
                      className="sm:h-10 sm:w-10"
                      src={pinterestIcon}
                      alt="..."
                      height={"30px"}
                      width={"30px"}
                    />
                    </a>
                  </div>
                </div>
              </div>
             
             {/* Social Media links */}
             
             <div className="xl:hidden md:hidden sm:w-[100%] flex mb-[20px] justify-around items-center gap-4 ">
                        
                <img
                  className="sm:h-7 sm:w-7"
                  src={fackbookIcon}
                  alt="..."
                  height={"30px"}
                  width={"30px"}
                />
                <img
                  className="sm:h-7 sm:w-7"
                  src={linkedinIcon}
                  alt="..."
                  height={"30px"}
                  width={"30px"}
                />

                <img
                  className="sm:h-7 sm:w-7"
                  src={twitterIcon}
                  alt="..."
                  height={"30px"}
                  width={"30px"}
                />
                <img
                  className="sm:h-7 sm:w-7"
                  src={instagramIcon}
                  alt="..."
                  height={"30px"}
                  width={"30px"}
                />
                <img
                  className="sm:h-7 sm:w-7"
                  src={pinterestIcon}
                  alt="..."
                  height={"30px"}
                  width={"30px"}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Only Section */}
        <div className="sm:flex md:hidden lg:hidden justify-around bg-white-A700 py-2 gap-6 px-4">
          <div className="flex justify-center items-center gap-2">
            <img src={"images/email.png"} alt="Email" className="h-4 w-4" />
            <a
              href="mailto:gchin@quickall.com"
              className="text-blue_gray-900_01 hover:text-black"
            >
              <p style={{ fontSize: "12px" }}> contact@quickall.com</p>
            </a>
          </div>
          <div className="flex justify-center items-center gap-2">
            <img src={"images/phone.png"} alt="Phone" className="h-4 w-4" />
            <a
              href="tel:+18187998174"
              className="text-blue_gray-900_01 hover:text-black"
            >
              <p style={{ fontSize: "12px" }}>+1 (818) 799-8174</p>
            </a>
          </div>
        </div>

        {/* Copyright Description Section */}
        <ul className="flex justify-around bg-white-A700 py-2 gap-6 lg:px-4 xl:pr-20">
          <div>
            <li>
              <div className="container-xs flex flex-wrap justify-center pr-[66px] sm:p-1 p-2">
                <div className="flex justify-center flex-row items-center">
                  <img src={copyrightIcon} alt="..." height={16} width={16} />
                  <Text as="p" className="sm:text-xs !text-blue_gray-900_01 pl-1">
                    Copyright 2024, Quickall Finance Inc.
                  </Text>
                </div>
              </div>
            </li>
          </div>

          <div className="sm:hidden sm:w-[100%] flex mb-[20px] justify-around items-center gap-4 ">
            <li>
              <Text as="p" className="cursor-pointer">
                <Link
                  to="/privacypolicy"
                  className="text-[black] hover:!text-black-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Privacy Policy
                </Link>
              </Text>
            </li>
            <li>
              <Text as="p" className="cursor-pointer">
                <Link
                  to="/termsofservices"
                  className="text-[black] hover:!text-black-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Terms of Services
                </Link>
              </Text>
            </li>
            
            <li>
              <Text
                as="p"
                className="text-[black] hover:!text-black-A700 whitespace-nowrap"
              >
                <Link
                  to="/disclaimer"
                  className="text-[black] hover:!text-black-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Disclaimer
                </Link>
              </Text>
            </li>
            
            <li>
              <Text
                as="p"
                className="text-[black] hover:!text-black-A700 whitespace-nowrap"
              >
                <Link
                  to="/contactus"
                  className="text-[black] hover:!text-black-A700 whitespace-nowrap"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </Link>
              </Text>
            </li>
            
          </div>
        </ul>
      </div>
    </footer>
  );
}
