import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button, Text, Heading, Img } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function CopyRightCertificate() {
  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex w-full flex-col items-center bg-white-A700">
        <Header className="self-stretch" />
        <div className="pt-20 md:pt-28 container-sm mt-[80px] pl-[110px] pr-[106px] md:p-5 md:px-5">
          <div className="flex flex-col items-left gap-[84px] md:gap-[63px] sm:gap-[42px]">
            <div className="flex flex-col items-start gap-12 self-stretch">
              <div className="flex w-full flex-col items-start gap-[40px] md:w-full md:gap-[35px] sm:gap-[25px]">
                <Heading as="h1" className="!text-black-900 text-center">
                  Quickall White Paper
                </Heading>
                
              </div>
              <div className="self-stretch bg-gradient pb-[46px] pl-[39px] pr-[37px] pt-12 md:py-5 sm:p-5">
                <Img
                  src="images/Certificate_white_paper.jpeg"
                  alt="imagefifteen"
                  className="h-full w-full object-cover md:h-auto"
                />
              </div>
            </div>
            <div className="flex w-full flex-col items-start md:w-full">
              <div className="flex flex-col items-start gap-[13px] self-stretch">
                <Heading size="s" as="h2" className="!text-gray-900_03">
                  About the white paper
                </Heading>
                <Text as="p" className="w-full !font-normal leading-7">
                The problem of centralization in web3 blockchains remains unresolved both for 
                blockchains using the Proof of Work consensus mechanism as well as for those who 
                have moved from the Proof of Work mechanism to the innovative Proof of Stake 
                concept like Ethereum or were created from scratch based on Proof of Stake 
                consensus mechanism like Ripple, Cardano, Polkadot, Solana, Algorand, etc.
                The “trusted behavior” of the validators for the validation process is the next 
                unsolved problem for many blockchain ecosystems like Ethereum, Ripple, Cardano, 
                Polkadot, Solana, etc. Because they cannot avoid/ignore the human factor, which
                 can manifest itself in "trustworthy" or "fraudulent" behavior at the same time.
                 This model is needed for verifying that a validator has not colluded with other 
                 validators to double-spend or steal funds from people who have deposited their
                  money into the blockchain ecosystem. 
                  <Link to="/contactus" style={{ display: 'inline-block' }} className="text-blue-500">Read more</Link> 
                  <b>(Please send your contact details to get a copy of white paper.)</b>
                </Text>
              </div>
              <div className="mt-[66px] flex flex-col items-start self-stretch">
                
                
                </div>
                            
            <div className="flex flex-col items-start gap-12 self-stretch">
              <div className="flex w-full flex-col items-start gap-[40px] md:w-full md:gap-[35px] sm:gap-[25px]">
                <Heading as="h1" className="!text-black-900 text-center">
                  Quickall Lite Paper
                </Heading>
                
              </div>
              <div className="self-stretch bg-gradient pb-[46px] pl-[39px] pr-[37px] pt-12 md:py-5 sm:p-5">
                <Img
                  src="images/Certificate_lite_paper.jpeg"
                  alt="imagefifteen"
                  className="h-full w-full object-cover md:h-auto"
                />
              </div>
            </div>
              {/*<div className="mt-16 flex self-stretch bg-gradient px-[42px] pb-[43px] pt-[42px] md:p-5">
                <Img
                  src="images/img_image_15_423x728.png"
                  alt="imagefifteen"
                  className="h-[423px] w-[96%] object-cover"
                />
              </div> */}

              
            </div>
            <div className="flex w-full flex-col items-center gap-10 md:w-full mb-10">
              <div className="flex flex-col gap-[40px] self-stretch md:gap-10 sm:gap-4">
                <Heading
                  as="h4"
                  className="!text-gray-900_03 text-center sm:text-3xl md:text-4xl text-5xl"
                >
                  Blockchain with Intelligence
                </Heading>
                <Text as="p" className="text-center leading-7">
                A blockchain system enhanced with artificial intelligence to automate processes, 
                improve efficiency, and enhance security within decentralized networks.
                </Text>
              </div>
              <Button
                color="teal_900"
                shape="square"
                className="mt-2 w-fit whitespace-normal border-2 border-solid border-white-A700 font-medium md:ml-0 sm:px-5"
              >
                <Link
                  to="/contactus"
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
                  Read More
                </Link>
              </Button>
            </div>
          </div>
        </div>
        <Footer className="mt-[72px] self-stretch" />
      </div>
    </>
  );
}
