import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Heading, Button, CheckBox, Input } from "../../components";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target?e.target.value:e);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target?e.target.value:e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoginError('');

    try {
      const response = await axios.post('https://quickall.io:3001/login', {
        email,
        password,
      });

      if (response.status === 200) {
        // Assume successful login redirects to the user dashboard
        if(response.data.token){
          localStorage.setItem("authToken",response.data.token)
          navigate('/userdashboard');
        }
        else{
          setLoginError('Invalid email or password. Please try again.');
        }
      }
    } catch (error) {
      setLoginError('Invalid email or password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>User Login</title>
        <meta
          name="description"
          content="Log in to your account with ease and security. Remember your credentials for quicker access. Forgot your password? Reset it here. Join us today if you don't have an account."
        />
      </Helmet>

      <Header className="self-stretch" />

      {/* login page section */}
      <div className="pt-20 sm:pt-6 md:pt-28 flex w-full justify-end bg-white-A700 pt-[45px] md:pt-5">
        {/* login content section */}
        <div className="mx-auto flex w-full max-w-[1566px] items-start justify-between gap-5 md:flex-col md:p-5">
          {/* pricing info section */}
          <div className="mb-[177px] flex w-[42%] mt-[20px] flex-col items-start gap-36 md:w-full sm:gap-[72px] pl-[110px] md:p-5 md:px-5 md:mb-10 md:mt-10">
            <div className="flex flex-col items-start gap-[49px] self-stretch pt-[70px] md:pt-5 forms-s">
              <a href="LOGIN" target="_blank" rel="noreferrer">
                <Text
                  size="lg"
                  as="p"
                  className="!text-black-900 bold"
                  style={{ fontSize: "30px" }}
                >
                  <b> Login</b>
                </Text>
                <Text
                  size="xs"
                  as="p"
                  className="mobile w-full leading-7 !text-black-900_99"
                >
                  Ready to continue your journey? Log in to access your personalized dashboard and 
                  explore the latest updates and features.
                </Text>
              </a>

              {/* login form section */}
              <div className="self-stretch">
                <form onSubmit={handleSubmit} className="self-stretch">
                  <div className="flex flex-col items-center">
                    <Text size="s" as="p" className="self-start !text-white-A700">
                      <span className="text-black-900">
                        <b>Email Address</b>&nbsp;
                      </span>
                      <span className="text-deep_orange_A400">*</span>
                    </Text>
                    <Input
                      shape="round"
                      type="email"
                      name="email"
                      placeholder="Enter email address"
                      prefix={
                        <div className="flex items-center justify-center">
                          <Img
                            src="images/padlock.png"
                            alt="lock"
                            className="h-[20px] w-[20px]"
                          />
                        </div>
                      }
                      className="mt-[19px] gap-[26px] sm:pr-5"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                    <div className="mt-[19px] flex flex-col items-start gap-[18px] self-stretch">
                      <Text size="s" as="p" className="!text-white-A700">
                        <span className="text-black-900">
                          <b>Password</b>&nbsp;
                        </span>
                        <span className="text-deep_orange_A400">*</span>
                      </Text>
                      <div className="relative w-full">
                        <Input
                          shape="round"
                          type={passwordVisible ? "text" : "password"}
                          name="password"
                          placeholder="Enter Your password"
                          className="gap-[35px] pr-10"
                          value={password}
                          onChange={handlePasswordChange}
                          required
                        />
                        <div
                          className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                          onClick={togglePasswordVisibility}
                        >
                          <Img
                            src={
                              passwordVisible
                                ? "images/view.png"
                                : "images/hide.png"
                            }
                            alt="eye"
                            className="h-[25px] w-[25px]"
                          />
                        </div>
                      </div>
                    </div>

                    {/* remember me section */}
                    <div className="mt-5 flex items-start justify-between w-full">
                      <CheckBox
                        name="Remember Checkbox"
                        label="Remember me"
                        id="RememberCheckbox"
                        className="gap-[13px] p-1 p-px text-base text-black-900 s m:text-sm"
                      />
                      <a href="/contactus">
                        <Text
                          as="p"
                          style={{ color: "#0000EE" }}
                          className="!text-indigo-A200 sm:text-sm sm:mt-[5px]"
                        >
                          Forgot Password?
                        </Text>
                      </a>
                    </div>

                    {/* login button section */}
                    <Button
                      shape="round"
                      className="mt-[40px] w-full"
                      style={{ color: "white" }}
                      type="submit"
                    >
                      {loading ? 'Logging in...' : 'Login'}
                    </Button>

                    {/* signup prompt section */}
                    <div className="mt-[27px] flex flex-row items-center">
                      <p className="text-black-900">
                        Don't have an account ?&nbsp;
                      </p>
                      <a
                        href="/heretoregister"
                        style={{ color: "#0000EE" }}
                        className="text-indigo-A200"
                      >
                        Sign Up
                      </a>
                    </div>
                  </div>
                </form>
                {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
              </div>
            </div>
          </div>

          {/* welcome message section */}
          <div className="flex w-[50%] flex-col mt-[100px] items-start gap-[1px] md:w-full md:mt-5 form-2-section">
            <Heading as="h1">Welcome Back</Heading>
            
            <Text size="s" as="p">
              Login to continue
            </Text>
            
            {/* description with logo section */}
            <div className="relative h-[588px] w-full md:h-[300px] mt-[20px]">
              <Text
                size="xs"
                as="p"
                className="w-full leading-7 !text-black-900_99"
              >
                Ready to continue your journey? Log in to access your personalized dashboard and 
                explore the latest updates and features.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
