import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button, Text, Img } from "../../components";
import Header from "../../components/Header";

export default function TestingPage() {
  // Countdown date setup
  const countdownDate = new Date("02/01/2025");

  // State to track days, hours, minutes, and seconds
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Effect to update the timer every second
  useEffect(() => {
    const interval = setInterval(() => setNewTime(), 1000);

    // Clear interval if seconds are less than 0 (timer ended)
    if (state.seconds < 0) {
      clearInterval(interval);
    }
  }, [state.seconds]);

  // Function to calculate and set the new time for countdown
  const setNewTime = () => {
    if (countdownDate) {
      const currentTime = new Date();
      const distanceToDate = countdownDate - currentTime;

      // Calculate days, hours, minutes, and seconds
      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      // Adding leading zero for single digit numbers
      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      days = `${days}`;
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      // Update the state with the new time values
      setState({ days, hours, minutes, seconds });
    }
  };

  return (
    <>
      {/* SEO setup */}
      <Helmet>
        <title>QUICKALL</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      {/* Main Container */}
  <div className="flex w-full flex-col items-center bg-white-A700">
        
        {/* Header Section */}
    <div className="flex flex-col items-center justify-center md:gap-[4px] gap-[10px] self-stretch bg-white-A700 pb-[132px] md:pb-5">
          <Header className="self-stretch w-full" />

                        {/* Web Banner Section */}
              <div className="pt-[58px] sm:hidden md:pt-28 w-full flex flex-col items-center gap-11 bg-white-a700" style={{width: "100%;position:relative"}}>
                
                {/* Image Section (Responsive) */}
                <div className="w-full h-auto flex flex-col items-center gap-[26px] relative">
                  
                  {/* Image and Join Now Button */}
                  <div className="w-full relative">
                    {/* Large Screen Image */}
                    <Img
                      src="images/bg_banner2.webp"
                      alt="hero image"
                      className="w-full h-auto object-cover"
                    />

                    {/* Join Now Button */}
                    <Link to="/Home1" style={{ color: "#ffffff", textDecoration: "none" }} 
                        onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }}>
                      <div className="absolute bottom-9 left-[490px]">
                        <Text className="!text-white-A700 text-[8px]">
                          Received USD from Bartering until today: $ 100,000
                        </Text>
                      </div>
                      <Button
                        shape="round"
                        size="text-2xl"
                        as="h3"
                        className="bg-gradient2 !text-white-A700 h-12 absolute top-[60%] left-[20%] transform -translate-x-1/2 -translate-y-1/2"
                      >
                        <Text className="text-4xl !text-white-A700">
                          &nbsp;&nbsp;Join Now&nbsp;&nbsp;
                        </Text>
                      </Button>
                    </Link>

                    {/* Countdown Timer */}
                    <div className="absolute top-[82.5%] left-[20%] transform -translate-x-1/2 -translate-y-1/2">
                      <div
                        className="ml-[50px] !text-yellow-500 text-[40px] md:text-[30px] font-bold tracking-wider"
                      >
                        {`${state.days || "00"}  :  ${state.hours || "00"}  :  ${state.minutes || "00"}   :  ${state.seconds || "00"}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          


              {/* Mobile Specific Banner Section */}

              <div className="pt-[190px] flex w-full h-auto flex-col items-center bg-teal-500 lg:hidden overflow-x-hidden" >
                <div className="w-full relative sm:gap-[5px]" style={{width: "100%;position:relative"}}>
                {/* First Mobile Image */}
                  <Img
                    src="images/mobile_bg1.webp"
                    alt="mobile background image 1"
                    className="w-full h-auto object-cover sm:mt-[-70px]" style={{width: "100%"}}
                    loading="lazy"
                  />                    
                    
                </div>

                <div className="w-full relative sm:gap-[20px]" style={{width: "100%;position:relative"}}>
                  
                  {/* Second Mobile Image */}
                  <Img
                    src="images/mobile_bg2.webp"
                    alt="mobile background image 2"
                    className="w-full h-auto object-cover sm:mt-[25px]"
                    loading="lazy"
                  />
                  {/* Third Mobile Image */}
                  <Img
                    src="images/mobile_bg3.webp"
                    alt="mobile background image 3"
                    className="w-full h-auto object-cover sm:mt-[25px]"
                    loading="lazy"
                  />
                </div>
                {/* Rectangular box with text */}
                <div className="w-full h-[60px] mt-6 bg-gradient2 flex items-center justify-center" style={{width: "100%;position:relative"}}>
                  <Text className="text-red-600 font-bold text-center text-2xl">
                    Join to decentralized the world!
                  </Text>
                </div>
                <div className="w-full relative sm:gap-[20px]" style={{width: "100%;position:relative"}}>
                  {/* Fourth Mobile Image */}
                  <Img
                    src="images/mobile_bg4.webp"
                    alt="mobile background image 1"
                    className="w-full h-auto object-cover sm:mt-[25px]"
                    loading="lazy"
                  />
                  {/* Countdown Timer for Mobile */}
                  
                    <div
                      className="absolute sm:ml-[8px] sm:text-yellow-500 sm:text-4xl sm:font-bold top-[17%] transform -translate-y-1/2 w-full flex items-center justify-center bg-transparent px-4"
                      style={{ letterSpacing: "0.3rem" }}
                    >
                      {`${state.days || "00"} : ${state.hours || "00"} : ${state.minutes || "00"} : ${state.seconds || "00"}`}
                    </div>
                   
                  {/* Fifth Mobile Image */}
                  <Img
                    src="images/mobile_bg5.webp"
                    alt="mobile background image 1"
                    className="w-full h-auto object-cover sm:mt-[25px]"
                    loading="lazy"
                  />
                  {/* Join Now Button on Mobile */}
                <div className="relative w-full h-full">
                  <Link to="/Home1" style={{ color: "#ffffff", textDecoration: "none" }}>
                    <Button
                      shape="round"
                      size="text-2xl"
                      as="h3"
                      className="bg-gradient2 !text-white-A700 h-12 absolute top-[30%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 sm:mt-[-50px]"
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }}
                    >
                      <Text className="text-white-A700 font-bold text-center text-2xl">
                        &nbsp;&nbsp;Join Now&nbsp;&nbsp;
                      </Text>
                    </Button>
                  </Link>
                </div>
                  {/* Sixth Mobile Image */}
                  <Img
                    src="images/mobile_bg6.webp"
                    alt="mobile background image 1"
                    className="w-full h-[60px] object-cover sm:mt-[25px]"
                    loading="lazy"
                  />
                  {/* Seventh Mobile Image */}
                  <Img
                    src="images/key.webp"
                    alt="mobile background image 1"
                    className="w-full h-auto object-cover sm:mt-[25px]"
                    loading="lazy"
                  />
                </div>
              </div>
            
    </div>
  </div>
    </>
  );
}
