import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Button } from "../../components";
import Header from "../../components/Header";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const countries = [
  { name: 'Afghanistan', code: '+93' },
  { name: 'Albania', code: '+355' },
  { name: 'Algeria', code: '+213' },
  { name: 'Andorra', code: '+376' },
  { name: 'Angola', code: '+244' },
  { name: 'Argentina', code: '+54' },
  { name: 'Armenia', code: '+374' },
  { name: 'Australia', code: '+61' },
  { name: 'Austria', code: '+43' },
  { name: 'Azerbaijan', code: '+994' },
  { name: 'Bahamas', code: '+1-242' },
  { name: 'Bahrain', code: '+973' },
  { name: 'Bangladesh', code: '+880' },
  { name: 'Belarus', code: '+375' },
  { name: 'Belgium', code: '+32' },
  { name: 'Belize', code: '+501' },
  { name: 'Benin', code: '+229' },
  { name: 'Bhutan', code: '+975' },
  { name: 'Bolivia', code: '+591' },
  { name: 'Bosnia and Herzegovina', code: '+387' },
  { name: 'Botswana', code: '+267' },
  { name: 'Brazil', code: '+55' },
  { name: 'Brunei', code: '+673' },
  { name: 'Bulgaria', code: '+359' },
  { name: 'Burkina Faso', code: '+226' },
  { name: 'Burundi', code: '+257' },
  { name: 'Cambodia', code: '+855' },
  { name: 'Cameroon', code: '+237' },
  { name: 'Canada', code: ' +1' },
  { name: 'Cape Verde', code: '+238' },
  { name: 'Central African Republic', code: '+236' },
  { name: 'Chad', code: '+235' },
  { name: 'Chile', code: '+56' },
  { name: 'China', code: '+86' },
  { name: 'Colombia', code: '+57' },
  { name: 'Comoros', code: '+269' },
  { name: 'Congo', code: '+242' },
  { name: 'Costa Rica', code: '+506' },
  { name: 'Croatia', code: '+385' },
  { name: 'Cuba', code: '+53' },
  { name: 'Cyprus', code: '+357' },
  { name: 'Czech Republic', code: '+420' },
  { name: 'Denmark', code: '+45' },
  { name: 'Djibouti', code: '+253' },
  { name: 'Dominican Republic', code: '+1-809' },
  { name: 'Ecuador', code: '+593' },
  { name: 'Egypt', code: '+20' },
  { name: 'El Salvador', code: '+503' },
  { name: 'Equatorial Guinea', code: '+240' },
  { name: 'Estonia', code: '+372' },
  { name: 'Ethiopia', code: '+251' },
  { name: 'Fiji', code: '+679' },
  { name: 'Finland', code: '+358' },
  { name: 'France', code: '+33' },
  { name: 'Gabon', code: '+241' },
  { name: 'Gambia', code: '+220' },
  { name: 'Georgia', code: '+995' },
  { name: 'Germany', code: '+49' },
  { name: 'Ghana', code: '+233' },
  { name: 'Greece', code: '+30' },
  { name: 'Grenada', code: '+1-473' },
  { name: 'Guatemala', code: '+502' },
  { name: 'Guinea', code: '+224' },
  { name: 'Guyana', code: '+592' },
  { name: 'Haiti', code: '+509' },
  { name: 'Honduras', code: '+504' },
  { name: 'Hungary', code: '+36' },
  { name: 'Iceland', code: '+354' },
  { name: 'India', code: '+91' },
  { name: 'Indonesia', code: '+62' },
  { name: 'Iran', code: '+98' },
  { name: 'Iraq', code: '+964' },
  { name: 'Ireland', code: '+353' },
  { name: 'Israel', code: '+972' },
  { name: 'Italy', code: '+39' },
  { name: 'Jamaica', code: '+1-876' },
  { name: 'Japan', code: '+81' },
  { name: 'Jordan', code: '+962' },
  { name: 'Kazakhstan', code: '+7' },
  { name: 'Kenya', code: '+254' },
  { name: 'Kiribati', code: '+686' },
  { name: 'Kuwait', code: '+965' },
  { name: 'Kyrgyzstan', code: '+996' },
  { name: 'Laos', code: '+856' },
  { name: 'Latvia', code: '+371' },
  { name: 'Lebanon', code: '+961' },
  { name: 'Lesotho', code: '+266' },
  { name: 'Liberia', code: '+231' },
  { name: 'Libya', code: '+218' },
  { name: 'Liechtenstein', code: '+423' },
  { name: 'Lithuania', code: '+370' },
  { name: 'Luxembourg', code: '+352' },
  { name: 'Madagascar', code: '+261' },
  { name: 'Malawi', code: '+265' },
  { name: 'Malaysia', code: '+60' },
  { name: 'Maldives', code: '+960' },
  { name: 'Mali', code: '+223' },
  { name: 'Malta', code: '+356' },
  { name: 'Marshall Islands', code: '+692' },
  { name: 'Mauritania', code: '+222' },
  { name: 'Mauritius', code: '+230' },
  { name: 'Mexico', code: '+52' },
  { name: 'Moldova', code: '+373' },
  { name: 'Monaco', code: '+377' },
  { name: 'Mongolia', code: '+976' },
  { name: 'Montenegro', code: '+382' },
  { name: 'Morocco', code: '+212' },
  { name: 'Mozambique', code: '+258' },
  { name: 'Myanmar', code: '+95' },
  { name: 'Namibia', code: '+264' },
  { name: 'Nauru', code: '+674' },
  { name: 'Nepal', code: '+977' },
  { name: 'Netherlands', code: '+31' },
  { name: 'New Zealand', code: '+64' },
  { name: 'Nicaragua', code: '+505' },
  { name: 'Niger', code: '+227' },
  { name: 'Nigeria', code: '+234' },
  { name: 'North Korea', code: '+850' },
  { name: 'Norway', code: '+47' },
  { name: 'Oman', code: '+968' },
  { name: 'Pakistan', code: '+92' },
  { name: 'Palau', code: '+680' },
  { name: 'Panama', code: '+507' },
  { name: 'Papua New Guinea', code: '+675' },
  { name: 'Paraguay', code: '+595' },
  { name: 'Peru', code: '+51' },
  { name: 'Philippines', code: '+63' },
  { name: 'Poland', code: '+48' },
  { name: 'Portugal', code: '+351' },
  { name: 'Qatar', code: '+974' },
  { name: 'Romania', code: '+40' },
  { name: 'Russia', code: '+7' },
  { name: 'Rwanda', code: '+250' },
  { name: 'Saint Kitts and Nevis', code: '+1-869' },
  { name: 'Saint Lucia', code: '+1-758' },
  { name: 'Samoa', code: '+685' },
  { name: 'San Marino', code: '+378' },
  { name: 'Saudi Arabia', code: '+966' },
  { name: 'Senegal', code: '+221' },
  { name: 'Serbia', code: '+381' },
  { name: 'Seychelles', code: '+248' },
  { name: 'Sierra Leone', code: '+232' },
  { name: 'Singapore', code: '+65' },
  { name: 'Slovakia', code: '+421' },
  { name: 'Slovenia', code: '+386' },
  { name: 'Solomon Islands', code: '+677' },
  { name: 'Somalia', code: '+252' },
  { name: 'South Africa', code: '+27' },
  { name: 'South Korea', code: '+82' },
  { name: 'Spain', code: '+34' },
  { name: 'Sri Lanka', code: '+94' },
  { name: 'Sudan', code: '+249' },
  { name: 'Suriname', code: '+597' },
  { name: 'Swaziland', code: '+268' },
  { name: 'Sweden', code: '+46' },
  { name: 'Switzerland', code: '+41' },
  { name: 'Syria', code: '+963' },
  { name: 'Taiwan', code: '+886' },
  { name: 'Tajikistan', code: '+992' },
  { name: 'Tanzania', code: '+255' },
  { name: 'Thailand', code: '+66' },
  { name: 'Togo', code: '+228' },
  { name: 'Tonga', code: '+676' },
  { name: 'Trinidad and Tobago', code: '+1-868' },
  { name: 'Tunisia', code: '+216' },
  { name: 'Turkey', code: '+90' },
  { name: 'Turkmenistan', code: '+993' },
  { name: 'Tuvalu', code: '+688' },
  { name: 'Uganda', code: '+256' },
  { name: 'Ukraine', code: '+380' },
  { name: 'United Arab Emirates', code: '+971' },
  { name: 'United Kingdom', code: '+44' },
  { name: 'United States', code: '+1' },
  { name: 'Uruguay', code: '+598' },
  { name: 'Uzbekistan', code: '+998' },
  { name: 'Vanuatu', code: '+678' },
  { name: 'Vatican City', code: '+379' },
  { name: 'Venezuela', code: '+58' },
  { name: 'Vietnam', code: '+84' },
  { name: 'Yemen', code: '+967' },
  { name: 'Zambia', code: '+260' },
  { name: 'Zimbabwe', code: '+263' },
  // Add more country codes as needed
];

export default function CreateaccountPage() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [countryCode, setCountryCode] = useState(countries[1].code);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(validateEmail(value) ? '' : 'Please enter a valid email address');
  };

  const validatePhone = (phone) => {
    const regex = /^[0-9]{8,12}$/;
    return regex.test(phone);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
    setPhoneError(validatePhone(value) ? '' : 'Please enter a valid phone number');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordError(e.target.value === password ? '' : 'Passwords do not match');
  };

  const handleTermsChange = (e) => {
    setTermsChecked(e.target.checked);
    setTermsError(e.target.checked ? '' : 'You must agree to the terms and conditions');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation before submitting
    if (!emailError && !phoneError && !passwordError && termsChecked) {
      setLoading(true);
      setApiError('');
      try {
        const response = await axios.post('https://quickall.io:3001/register', {
          email,
          phone: `${countryCode}${phone}`,
          password,
        });

        if (response.status === 200) {
          alert('Account created successfully!');
          navigate('/login');  // Redirect to login page after successful account creation
        }
      } catch (error) {
        setApiError('Failed to create account. Please try again later.');
      } finally {
        setLoading(false);
      }
    } else {
      if (!termsChecked) setTermsError('You must agree to the terms and conditions');
      alert('Please fix the errors in the form.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Account</title>
        <meta
          name="description"
          content="Join us by creating an account quickly. Sign up using your email or phone number and start enjoying our services. Secure and straightforward registration process."
        />
      </Helmet>
      <Header className="self-stretch" />

      {/* main content section */}
      <div className="pt-20 sm:pt-6 md:pt-28 bg-white-A700 pb-[45px]">
        <div>
          <div className="flex items-start mt-[80px] justify-between gap-5 md:flex-col  pl-[110px] md:p-5 md:px-5">
            <div className="mt-1 w-[39%] md:w-full">
              <div className="flex flex-col items-start gap-[30px]">
                <Text
                  size="lg"
                  as="p"
                  className="!text-black-900 bold"
                  style={{ fontSize: "30px" }}
                >
                  <b> Create your account</b>
                </Text>
                <Text
                  size="xs"
                  as="p"
                  className="mobile w-full leading-7 !text-black-900_99"
                >
                  To get started, we need you to create an account with us.
                  It&#39;s a quick and straight forward process that will only
                  take a few minutes.
                </Text>
              </div>
                {/* input fields section */}
                <form className="CreateaccountPage" onSubmit={handleSubmit}>
                  <div className="flex items-start gap-6 self-stretch sm:flex-col forms-s ">
                    <div className="flex flex-1 flex-col gap-[21px] sm:self-stretch">
                      <div className="flex flex-col gap-[19px]">
                        <div className="flex flex-col items-start gap-[11px]">
                          <Text as="p">
                            <span className="text-black-900 ">
                              <b>Email Address</b>&nbsp;
                            </span>
                            <span className="text-deep_orange_A400">*</span>
                          </Text>
                          <input
                            className="h-12 custom-border"
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Enter email address"
                            required
                          />
                          {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                        </div>
                        <div className="flex flex-col items-start">
                          <Text as="p">
                            <span className="text-black-900">
                              <b>Phone No</b>.&nbsp;
                            </span>
                            <span className="text-deep_orange_A400">*</span>
                          </Text>
                          <select value={countryCode} onChange={(e) => setCountryCode(e.target.value)} className="sm:text-xs p-2 custom-border border-gray-300 rounded-lg w-[300px]">
                            {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                                {country.name} ({country.code})
                              </option>
                            ))}
                          </select>

                          <input
                            className="h-12 custom-border"
                            type="tel"
                            value={phone}
                            onChange={handlePhoneChange}
                            placeholder="Enter phone number"
                            required
                          />
                          {phoneError && <p style={{ color: 'red' }}>{phoneError}</p>}
                        </div>
                      </div>

                      <div className="flex flex-col items-start gap-2.5">
                        <Text as="p">
                          <span className="text-black-900">
                            <b>Password</b>&nbsp;
                          </span>
                          <span className="text-deep_orange_A400">*</span>
                        </Text>
                        <input
                          className="h-12 custom-border"
                          type="password"
                          value={password}
                          onChange={handlePasswordChange}
                          placeholder="Set password"
                          required
                        />
                      </div>

                      <div className="flex flex-col items-start gap-[11px]">
                        <Text as="p">
                          <span className="text-black-900">
                            <b>Confirm Password </b>&nbsp;
                          </span>
                          <span className="text-deep_orange_A400">*</span>
                        </Text>
                        <input
                          className="h-12 custom-border"
                          type="password"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          placeholder="Confirm password"
                          required
                        />
                        {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
                      </div>

                      {/* terms and conditions section */}
                      <div className="flex flex-col gap-[35px] mt-[40px]">
                        <input type="checkbox" checked={termsChecked} onChange={handleTermsChange} required />
                        <div className="mt-[-70px] ml-[40px]">
                          Your personal data will be used to support your experience throughout 
                          this website, to manage access to your account, and for other 
                          purposes described in our &nbsp;
                          <a style={{ display: 'inline-block' }} href="/privacypolicy" target="_blank" rel="noopener noreferrer" className="text-blue-500">Privacy Policy</a>
                          &nbsp;and&nbsp;
                          <a href="/termsofservices" style={{ display: 'inline-block' }} target="_blank" rel="noopener noreferrer" className="text-blue-500">Terms & Condition</a>
                        </div>
                        <Button shape="round" className="w-full" type="submit" style={{ color: "#ffffff"}}>
                          {loading ? 'Creating...' : 'Create an Account'}
                        </Button>
                      </div>
                      {apiError && <p style={{ color: 'red' }}>{apiError}</p>}
                    </div>
                  </div>
                </form>
              
            </div>

            <div className="w-[34%] sm:hidden relative h-[792px]">
              <div className="absolute h-[792px] inset-[0] m-auto">
                {/* background image 
                <img
                  src="images/img_createaccountpage.png"
                  className="h-[792px] m-auto object-cover w-full"
                  alt="create account page background"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
