import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Header1 from "components/Header1";
import Footer from "components/Footer";
import { Helmet } from "react-helmet";

//const stripePromise = loadStripe("pk_test_51Q13FqRwqXFlW2QytNmjdjoCFejVYIRjJJNOm3ETGRASdva0K9eQO4iwMvxKIw5EXB3WJPCdP2I5wssne2lufAi700bC5fT5n5");
const stripePromise = loadStripe("pk_live_51Q13FqRwqXFlW2QyPRUua9LtK19VY1C7qLCbbVbivCwkDc4Dhjvq4isZbqTSY4fhmpUeDoEHQOMphgBDCvgVmBEW00DuWnMp1R");

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tokenNew = localStorage.getItem("authToken");
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin,
      },
      redirect: "if_required",
    });

    if (result.error) {
      setMessage({ text: `Payment failed: Please retry !!`, type: "error" });
      setIsSubmitting(false);
    } else {
      console.log(result.id);
      try {
        axios
          .post(
            "https://quickall.io:3001/update-status",
            {
              transactionId: result.paymentIntent.id,
            },
            {
              headers: {
                Authorization: "Bearer " + tokenNew,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            setMessage({
              text: "Payment processed successfully. Redirecting...",
              type: "success",
            });
      
            // Redirect after a short delay
            setTimeout(() => {
              navigate("/userdashboard");
            }, 3000);
          })
          .catch((error) => {
            console.error("Error updating status:", error);
            setMessage({
              text: "Payment succeeded, but there was an error updating status.",
              type: "error",
            });
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } catch (error) {
        console.error("Unexpected error:", error);
        setMessage({
          text: "Payment succeeded, but there was an error updating status.",
          type: "error",
        });
        setIsSubmitting(false);
      }      
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button style={{
        backgroundColor: isSubmitting ? "#d3d3d3" : "#007BFF", // Gray when disabled, blue when enabled
        marginTop: "20px", // Adjust the value as needed
        color: "#ffffff",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: isSubmitting ? "not-allowed" : "pointer", // Change cursor to 'not-allowed' when disabled
        border: "none",
        borderRadius: "5px",
        opacity: !stripe ? 0.6 : 1, // Make the button semi-transparent when disabled
      }} disabled={isSubmitting || !stripe}>Submit</button>
      {message && (
        <div style={{
          marginTop: "20px",
          padding: "15px",
          borderRadius: "8px",
          color: message.type === "error" ? "#721c24" : "#155724",
          backgroundColor: message.type === "error" ? "#f8d7da" : "#d4edda",
          border: `1px solid ${message.type === "error" ? "#f5c6cb" : "#c3e6cb"}`,
          fontSize: "16px",
          textAlign: "center",
        }}>
          {message.text}
        </div>
      )}
    </form>
  );
};

const InjectedCheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation(); // Retrieve state passed from navigate
  const { amount } = location.state || {}; // Get the amount
  const token = localStorage.getItem("authToken");
  const [username, setUsername] = useState("");
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get("https://quickall.io:3001/login", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        if (response.data && response.data.user) {
          setUsername(response.data.user);
        }
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    };

    if (token) {
      fetchUserDetails();
    } else {
      console.error("No token found, redirecting to login...");
    }
  }, [token]);
  useEffect(() => {
    if (!amount) return; // Exit if amount is not defined

    const token = localStorage.getItem("authToken");
    axios.post("https://quickall.io:3001/process-payment", { amount: amount }, { // Amount in cents
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        setClientSecret(response.data.client_secret);
      })
      .catch((error) => {
        console.error("Error fetching client secret:", error);
      });
  }, [amount]);

  const options = {
    clientSecret: clientSecret,
  };

  return (
    clientSecret && (
      <>
        <Helmet>
          <title>QUICKALL</title>
          <meta name="description" content="Web site created using create-react-app" />
        </Helmet>
        <div className="flex w-full flex-col items-center gap-5 bg-white-A700 md:gap-24 sm:gap-16">
          <Header1 className="self-stretch" username={username} />
          <div className="pt-20 sm:pt-10 md:pt-28 container-sm pl-[75px] pr-[75px] md:p-5 md:px-5 z-[49]">
            <div className="flex flex-col items-center gap-[25px] sm:gap-[33px]">
              <div className="flex w-[67%] flex-col items-center gap-[7px] md:w-full"></div>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
              </Elements>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  );
};

export default InjectedCheckoutForm;
