import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Footer from "../../components/Footer";
import Header1 from "../../components/Header1";
import { Button, Img } from "components";

export default function UserDashboardPage() {
  const [username, setUsername] = useState("");
  const [certs, setCerts] = useState([]);
  const token = localStorage.getItem("authToken"); // Example token fetching
  const downloadCertificate = async (path) => {
    try {
      const response = await axios.post('https://quickall.io:3001/downloadcert', 
        { path },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in headers
          },
          responseType: 'blob' // Set response type to blob to handle binary data
        }
      );
  
      // Create a link element and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', path.split('/').pop()); // Extract the file name from the path
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error('Error downloading certificate:', error);
    }
  };
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get("https://quickall.io:3001/login", {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });

        if (response.data && response.data.user) {
          setUsername(response.data.user);

          fetch('https://quickall.io:3001/get-certs', {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
            .then(response => response.json())
            .then(data => setCerts(data))
            .catch(error => console.error('Error fetching certificates:', error));
        }
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    };

    if (token) {
      fetchUserDetails();
    }
  }, [token]);

  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta name="description" content="User Dashboard - Certificates" />
      </Helmet>
      <div className="flex w-full flex-col items-center bg-white-A700">
        <Header1 className="self-stretch" username={username} />

        <div className="pt-20 md:pt-28 container mt-[80px] px-5 mb-10">
          <div className="flex flex-col items-left gap-[84px] md:gap-[63px] sm:gap-[42px]">
            <div className="flex flex-col items-start gap-12 self-stretch">
              <div className="self-stretch bg-gradient pb-[46px] pl-[39px] pr-[37px] pt-12 md:py-5 sm:p-5">
                <Img
                  src="images/Gold_Certificate.webp"
                  alt="imagefifteen"
                  className="h-full w-full object-cover md:h-auto"
                />
              </div>
            </div>
            <h2 className="text-2xl font-bold mb-8">Certificates</h2>

            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 px-4 py-2 text-left">Certificate ID</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Certificate Path</th>
                </tr>
              </thead>
              <tbody>
                {certs.map(cert => (
                  <tr key={cert.Id} className="hover:bg-gray-100">
                    <td className="border border-gray-300 px-4 py-2">{cert.Id}</td>
                    <td className="border border-gray-300 px-4 py-2">
                    <Button onClick={() => downloadCertificate(cert.certspath)} className="z-[40]">
                          View Certificate
                        </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Footer with margin-top added to create a gap */}
        <Footer className="mt-10 self-stretch" />
      </div>
    </>
  );
}
