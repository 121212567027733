import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button, Text, Heading, Img } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function BarterCertificatePage() {
  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex w-full flex-col items-center bg-white-A700">
        <Header className="self-stretch" />
        <div className="pt-20 sm:pt-14 md:pt-28 container-sm mt-[80px] pl-[110px] pr-[106px] md:p-5 md:px-5">
          <div className="flex flex-col items-left gap-[84px] md:gap-[63px] sm:gap-[42px]">
            <div className="flex flex-col items-start gap-12 self-stretch">
              <div className="flex w-full flex-col items-start gap-[40px] md:w-full md:gap-[35px] sm:gap-[25px]">
                <Heading as="h1" className="!text-black-900 text-center">
                  Barter Certificate
                </Heading>
                <Text as="p" className="w-full">
                Quickall's Barter Certificate Plans are designed for everyone. 
                Select a suitable plan to become a Money Manager and participate in secure, 
                efficient trading.
                </Text>
              </div>
              <div className="self-stretch bg-gradient md:bg-gradient sm:bg-none pb-[46px] pl-[39px] pr-[37px] pt-12 md:py-5 sm:p-5">
                <Img
                  src="images/Gold_Certificate.webp"
                  alt="imagefifteen"
                  className="h-full w-full object-cover md:h-auto"
                />
              </div>
            </div>
            <div className="flex w-full flex-col items-start md:w-full">
              <div className="flex flex-col items-start gap-[13px] self-stretch">
                <Heading size="s" as="h2" className="!text-gray-900_03">
                  About the Barter Certificate
                </Heading>
                <Text as="p" className="w-full !font-normal leading-7">
                The Quickall Barter Certificate is a unique financial instrument issued by 
                Quickall Finance Inc. to provide money managers with early access to the Quickall 
                ecosystem’s future utility token, GGQ coins. By purchasing a Barter Certificate with 
                US dollars, money managers receive the right to exchange it for an equivalent value of 
                GGQ coins upon the completion of the Quickall blockchain development.
                </Text>
              </div>
              <div className="mt-7 flex w-[90%] flex-col gap-[19px] md:w-full">
                <div className="sm:items-start flex w-[90%] items-center sm:gap-1 gap-4 md:w-full">
                  <div className="sm:mt-2 flex h-[5px] w-[4px] rounded-sm bg-blue_gray-900_01" />
                  <Text as="p" className="!font-normal flex flex-1">
                  The Barter Certificate offers money manager an opportunity to participate in the Quickall ecosystem’s growth by securing future GGQ coins at an early stage.
                  </Text>
                </div>
                <div className="sm:items-start flex items-center sm:gap-1 gap-4">
                  <div className="sm:mt-2 flex h-[5px] w-[4px] rounded-sm bg-blue_gray-900_01" />
                  <Text as="p" className="!font-normal flex flex-1">
                  Each Barter Certificate represents a specific value in US dollars, which can be converted to an equivalent value in GGQ coins once the blockchain is operational.
                  </Text>
                </div>
                <div className="sm:items-start flex items-start sm:gap-1 gap-4">
                  <div className="sm:mt-2 flex mt-[9px] h-[5px] w-[4px] rounded-sm bg-blue_gray-900_01" />
                  <Text as="p" className="!font-normal flex flex-1">
                  If the blockchain development is not completed within six (6) months from the date of the certificate's issuance, Quickall Finance Inc. will refund the invested amount along with 6% annual interest, ensuring investor protection.
                  </Text>
                </div>
                <div className="sm:items-start flex items-start sm:gap-1 gap-4">
                  <div className="sm:mt-2 flex mt-[9px] h-[5px] w-[4px] rounded-sm bg-blue_gray-900_01" />
                  <Text as="p" className="!font-normal flex flex-1">
                  Quickall Finance Inc. commits to complying with all relevant financial regulations, ensuring the process is transparent and secure for all participants.

                  </Text>
                </div>
              </div>

              {/*<div className="mt-16 flex self-stretch bg-gradient px-[42px] pb-[43px] pt-[42px] md:p-5">
                <Img
                  src="images/img_image_15_423x728.png"
                  alt="imagefifteen"
                  className="h-[423px] w-[96%] object-cover"
                />
              </div> */}

              <div className="mt-[66px] flex flex-col items-start self-stretch">
                <Heading size="s" as="h3" className="!text-gray-900_03">
                  How you claim it after 06 months?
                </Heading>
                <Text as="p" className="mt-2 w-full !font-normal leading-7">
                Claiming your GGQ coins with the Quickall Barter Certificate is a straightforward 
                process designed to be user-friendly and efficient. Here’s how you can claim your 
                GGQ coins after the stipulated six (6) months period
                </Text>
                <div className="mt-7 flex w-[90%] flex-col gap-[19px] md:w-full">
                  <div className="sm:items-start flex w-[90%] items-center sm:gap-1 gap-4 md:w-full">
                    <div className="sm:mt-2 flex h-[5px] w-[4px] rounded-sm bg-blue_gray-900_01" />
                    <Text as="p" className="!font-normal flex flex-1">
                    Verify the completion status of the Quickall blockchain development through official announcements on our website or by contacting Quickall customer support.
                    </Text>
                  </div>
                  <div className="sm:items-start flex items-center sm:gap-1 gap-4">
                    <div className="sm:mt-2 flex h-[5px] w-[4px] rounded-sm bg-blue_gray-900_01" />
                    <Text as="p" className="!font-normal flex flex-1">
                    Log in to your account on the Quickall website (www.quickall.com). Ensure your account details are up to date to facilitate the claim process.
                    </Text>
                  </div>
                  <div className="sm:items-start flex items-start sm:gap-1 gap-4">
                    <div className="sm:mt-2 flex mt-[9px] h-[5px] w-[4px] rounded-sm bg-blue_gray-900_01" />
                    <Text as="p" className="!font-normal flex flex-1">
                    Access the dedicated section for Barter Certificate holders. This section will provide detailed instructions on how to initiate your claim.
                    </Text>
                  </div>
                  <div className="sm:items-start flex items-start sm:gap-1 gap-4">
                    <div className="sm:mt-2 flex mt-[9px] h-[5px] w-[4px] rounded-sm bg-blue_gray-900_01" />
                    <Text as="p" className="!font-normal flex flex-1">
                    Enter the details of your Barter Certificate, including the certificate number and value. Follow the prompts to exchange your certificate for the equivalent value in GGQ coins.
                    </Text>
                  </div>
                  <div className="sm:items-start flex items-start sm:gap-1 gap-4">
                    <div className="sm:mt-2 flex mt-[9px] h-[5px] w-[4px] rounded-sm bg-blue_gray-900_01" />
                    <Text as="p" className="!font-normal flex flex-1">
                    Quickall will verify the authenticity of your Barter Certificate. This may include cross-checking your registered details and the investment amount.
                    </Text>
                  </div>
                  <div className="sm:items-start flex items-start sm:gap-1 gap-4">
                    <div className="sm:mt-2 flex mt-[9px] h-[5px] w-[4px] rounded-sm bg-blue_gray-900_01" />
                    <Text as="p" className="!font-normal flex flex-1">
                    Once verified, the equivalent value of GGQ coins will be credited to your Quickall account. You will receive a confirmation email detailing the successful exchange.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col items-center gap-10 md:w-full mb-10">
              <div className="flex flex-col gap-[40px] self-stretch md:gap-10 sm:gap-4">
                <Heading
                  as="h4"
                  className="!text-gray-900_03 text-center sm:text-3xl md:text-4xl text-5xl"
                >
                  Blockchain with Intelligence
                </Heading>
                <Text as="p" className="text-center leading-7">
                A blockchain system enhanced with artificial intelligence to automate processes, 
                improve efficiency, and enhance security within decentralized networks.
                </Text>
              </div>
              <Button
                color="teal_900"
                shape="square"
                className="mt-2 w-fit whitespace-normal border-2 border-solid border-white-A700 font-medium md:ml-0 sm:px-5"
              >
                <Link
                  to="/heretoregister"
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
                  Lets Start
                </Link>
              </Button>
            </div>
          </div>
        </div>
        <Footer className="mt-[72px] self-stretch" />
      </div>
    </>
  );
}
