import React from "react";
import { Text, Img } from "./..";

export default function WorkWorkcard({
  imageseven = "images/img_image_7.png",
  imagefifteen = "images/img_image_15_317x545.png",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex flex-col w-full gap-[92px] md:gap-[69px] sm:gap-[46px]`}>
      <div className="relative h-[380px] self-stretch md:h-auto">
        <Img src="images/img_image_1.png" alt="imageone" className="h-[380px] w-full object-cover" />
        <div className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[380px] w-full md:h-auto">
          <Img src={imageseven} alt="imageseven" className="h-[380px] w-full object-cover" />
          <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max w-full justify-center bg-gradient px-[30px] pb-[33px] pt-[30px] sm:p-5">
            <Img src={imagefifteen} alt="imagefifteen" className="h-[317px] w-[97%] object-cover" />
          </div>
        </div>
      </div>
      <Text as="p" className="w-full text-justify leading-7">
        {description}
      </Text>
    </div>
  );
}
