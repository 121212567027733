import React from "react";
import { Helmet } from "react-helmet";
import { Text, Heading } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import WorkWorkcard from "../../components/WorkWorkcard";

const data = [
  {
    imageseven: "images/img_image_7.png",
    imagefifteen: "images/img_image_15_317x545.png",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    imageseven: "images/img_image_7_380x622.png",
    imagefifteen: "images/img_image_15_1.png",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    imageseven: "images/img_image_7.png",
    imagefifteen: "images/img_image_15_2.png",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    imageseven: "images/img_image_7_380x622.png",
    imagefifteen: "images/img_image_15_3.png",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    imageseven: "images/img_image_7.png",
    imagefifteen: "images/img_image_15_4.png",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    imageseven: "images/img_image_7_380x622.png",
    imagefifteen: "images/img_image_15_5.png",
    description:
      "Apparently we had reached a great height in the atmosphere, for the sky was a dead black, and the stars had ceased to twinkle.",
  },
];

export default function WorkPage() {
  return (
    <>
      <Helmet>
        <title>QUICKALL</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-A700">
        <Header />
        <div className="pt-20 flex flex-col items-center gap-[182px] md:gap-[136px] sm:gap-[91px]">
          <div className="flex justify-center self-stretch bg-gray-100 pb-36 pt-[99px] md:py-5">
            <div className="container-sm flex justify-center pl-[276px] pr-[271px] md:p-5 md:px-5">
              <div className="flex w-full flex-col items-center">
                <Text as="p" className="relative z-[1]">
                  What we have accomplished{" "}
                </Text>
                <div className="flex flex-col items-center gap-5 self-stretch">
                  <Heading as="h1" className="!text-black-900">
                    Our Work
                  </Heading>
                  <Text as="p" className="w-full text-center leading-7">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="container-sm md:p-5">
            <div className="grid grid-cols-2 justify-center gap-[34px] md:grid-cols-1">
              {data.map((d, index) => (
                <WorkWorkcard {...d} key={"work" + index} />
              ))}
            </div>
          </div>
        </div>
        <Footer className="mt-[82px]" />
      </div>
    </>
  );
}
