import React from "react";
import { Text, Img } from "./..";

export default function AboutUsCard({ name = "Nitin Sahni", designation = "CTO", imgSrc, ...props}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center w-fit sm:p-5 bg-white-A700`}
    >
      <Img src={imgSrc} alt="profileimage" className="h-[168px] w-[168px] rounded-[50%]" />
      <Text size="lg" as="p" className="mt-7 !text-gray-900_01">
        {name}
      </Text>
      <Text as="p" className="!font-normal !text-gray-900_b2">
        {designation}
      </Text>
    </div>
    // src="images/img_https_www_pex_2.png"
  );
}
